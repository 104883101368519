<script>
import "@plugins/fancybox/jquery.fancybox.css"
import "@plugins/fancybox/jquery.fancybox.js"

export default {
  props: {
    title: {default:"File Preview"},
    rel: String,
    src: String,
  },
    computed:{
        uploader(){ return this.$root.page.uploader },
        isImage(){ return ["jpeg","jpg","png","gif"].indexOf(this.src.split(".")[1])>-1 }
    },
  mounted: function(){
    if(!this.rel){
      $(this.$el).fancybox();
    }else{
      setTimeout(()=>{
        $("[rel="+this.rel+"]").fancybox();
      }, 300)
    }
  },
};
</script>

<template>
    <a :href="src" :rel="rel" style="min-height: 109px;font-size: 63px;" :target="isImage?false:'_blank'" class="btn btn-dark" v-tooltip="title"><i class="icon-file"></i><i class="ti-file"></i></a>
</template>

